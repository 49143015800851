import {
  Calculator,
  Offer,
  OfferEvaluation,
  OfferEvaluationData,
  TenderStateEnum,
  hasValue,
} from '@dims/components';
import PriceTypesEnum from '@/models/PriceTypes';
import { DraftTender5086, Tender5086 } from '@/models/Tender';
import { Offer5086 } from '@/models/Offer';
import PropellantEnum from '../models/PropellantTypes';
import tcoVariables from './tcoVariables';
import { BPQCriteriaEnum } from '../services/bpqCriteria';

export const tenderTotalCars = (offer: Offer5086, tender: Tender5086): number => (tender.data.carsBoughtInitially ?? 0)
  + (tender.data.optionalCarsAfterAgreementSelected ? (tender.data.optionalCarsAfterAgreement ?? 0) : 0);

export const priceInclRegistrationPerVehicle = (offer: Offer5086): number => (offer.data.priceTotal
  ?? 0) + (offer.data.registrationTax ?? 0);

export const priceModificationPerVehicle = (offer: Offer5086, tender: Tender5086): number => {
  let priceVehicleModification;
  if (tender.data.vehicleModificationPrice === PriceTypesEnum.FIXED_PRICE) {
    priceVehicleModification = offer.data.priceVehicleModificationFixed ?? 0;
  } else {
    priceVehicleModification = (tender.data.vehicleModificationEstimatedHours ?? 0)
      * (offer.data.priceVehicleModificationHourlyRate ?? 0);
  }
  return priceVehicleModification;
};

export const priceModificationPerVehicleFixed = (offer: Offer5086, tender: Tender5086): number => {
  let priceVehicleModification;
  if (tender.data.vehicleModificationPrice === PriceTypesEnum.FIXED_PRICE) {
    priceVehicleModification = offer.data.priceVehicleModificationFixed ?? 0;
  }
  return priceVehicleModification ?? 0;
};

export const totalServiceCosts = (offer: Offer5086): number => {
  const od = offer.data;
  return (od.priceService ?? 0)
    + (od.priceRepairs ?? 0)
    + (od.priceCollectDeliverService ?? 0)
    + (od.priceTireAgreement ?? 0);
};

export const totalYearlyServiceCosts = (offer: Offer5086, tender: Tender5086): number => {
  const od = offer.data;
  return (
    (od.priceService ?? 0)
    + (od.priceRepairs ?? 0)
    + (od.priceCollectDeliverService ?? 0)
    + (od.priceTireAgreement ?? 0)
  ) / (tender.data.tcoYears ?? 0);
};

export const subTotal = (offer: Offer5086, tender: Tender5086): number => {
  const od = offer.data;
  return (
    (od.priceTotal ?? 0)
    + priceModificationPerVehicle(offer, tender)
    + totalServiceCosts(offer)
    + (od.priceServicePeriod ?? 0)
    + (od.priceDeliveryAtCustomer ?? 0)
  );
};

export const skiSharePerVehicle = (offer: Offer5086, tender: Tender5086): number => subTotal(offer, tender) * 0.01;

export const priceDeliveryPerVehicle = (offer: Offer5086): number => {
  const { data } = offer;
  return (data.priceDelivery ?? 0) + (data.priceDeliveryAtCustomer ?? 0);
};

const sumOfAllCustomerCars = (offer: Offer5086): number => {
  const od = offer.data;
  return (
    (od.priceBuyCustomersCar1 ?? 0)
    + (od.priceBuyCustomersCar2 ?? 0)
    + (od.priceBuyCustomersCar3 ?? 0)
    + (od.priceBuyCustomersCar4 ?? 0)
    + (od.priceBuyCustomersCar5 ?? 0)
  );
};

export const totalCosts = (offer: Offer5086, tender: Tender5086): number => (
  subTotal(offer, tender)
  + skiSharePerVehicle(offer, tender)
  + (offer.data.priceDelivery ?? 0)
  + (offer.data.registrationTax ?? 0)
);

export const contractSum = (offer: Offer5086, tender: Tender5086): number => (
  (tender.data.carsBoughtInitially ?? 0) * totalCosts(offer, tender)
  - sumOfAllCustomerCars(offer)
);

export const isOfferElectric = (offer: Offer5086) => offer.data.propellantType === PropellantEnum.ELECTRICITY
  || offer.data.propellantType === PropellantEnum.PLUGIN_HYBRID;

export const fuelPriceInYear = (offer: Offer5086, year: number): number => {
  switch (offer.data.propellantType) {
    case PropellantEnum.GASOLINE:
    case PropellantEnum.GASOLINE_HYBRID:
      return tcoVariables.priceGasoline * (1 + tcoVariables.gasolinePriceIncreaseRatio / 100) ** (year - 1);
    case PropellantEnum.DIESEL:
    case PropellantEnum.DIESEL_HYBRID:
      return tcoVariables.priceDiesel * (1 + tcoVariables.dieselPriceIncreaseRatio / 100) ** (year - 1);
    case PropellantEnum.ELECTRICITY:
    case PropellantEnum.PLUGIN_HYBRID:
      if (!(offer.data.chargingSubscription && offer.data.chargingSubscription > 0)) {
        return tcoVariables.priceElectric * (1 + tcoVariables.electricPriceIncreaseRatio / 100) ** (year - 1);
      }
      break;
    default:
      break;
  }
  return 0;
};

export const operatingCostsIndividualYear = (year: number, offer: Offer5086, tender: Tender5086): number => {
  let operatingCostsPerCarInYear = 0;
  const td = tender.data;
  const od = offer.data;
  const fuelPrice = fuelPriceInYear(offer, year);
  if (td.tcoYears && td.expectedYearlyDrivingDistance) {
    switch (od.propellantType) {
      case PropellantEnum.GASOLINE:
      case PropellantEnum.GASOLINE_HYBRID:
        if (od.consumptionICE) {
          operatingCostsPerCarInYear = ((td.expectedYearlyDrivingDistance / od.consumptionICE) * fuelPrice)
            * (1 + tcoVariables.discountRate / 100) ** -year;
        }
        break;
      case PropellantEnum.DIESEL:
      case PropellantEnum.DIESEL_HYBRID:
        if (od.consumptionICE) {
          operatingCostsPerCarInYear = ((td.expectedYearlyDrivingDistance / od.consumptionICE) * fuelPrice)
            * (1 + tcoVariables.discountRate / 100) ** -year;
        }
        break;
      case PropellantEnum.ELECTRICITY:
      case PropellantEnum.PLUGIN_HYBRID:
        if (od.chargingSubscription && od.chargingSubscription > 0) {
          operatingCostsPerCarInYear = od.chargingSubscription
            * (1 + tcoVariables.discountRate / 100) ** -year;
        } else if (od.consumptionElectric) {
          operatingCostsPerCarInYear = (((td.expectedYearlyDrivingDistance * od.consumptionElectric) / 1000) * fuelPrice)
            * (1 + tcoVariables.discountRate / 100) ** -year;
        }
        break;
      default:
        break;
    }
  }
  return operatingCostsPerCarInYear * tenderTotalCars(offer, tender);
};

export const operatingCosts = (offer: Offer5086, tender: Tender5086): number => {
  let totalOperatingCosts = 0;
  if (!tender.data.tcoYears || !tender.data.expectedYearlyDrivingDistance || !tender.data.tcoYears) return totalOperatingCosts;
  for (let year = 1; year <= tender.data.tcoYears; year += 1) {
    totalOperatingCosts += operatingCostsIndividualYear(year, offer, tender);
  }
  return totalOperatingCosts;
};

export const serviceCostsIndividualYear = (year: number, offer: Offer5086, tender: Tender5086): number => {
  const td = tender.data;
  const od = offer.data;
  const { discountRate } = tcoVariables;
  let tempServiceCosts = 0;
  if (td.tcoYears) {
    tempServiceCosts
      += (totalYearlyServiceCosts(offer, tender) + (od.greenOwnerTax ?? 0))
      * (1 + discountRate / 100) ** -year;
    tempServiceCosts *= tenderTotalCars(offer, tender);
  }
  return tempServiceCosts;
};

export const serviceCosts = (offer: Offer5086, tender: Tender5086): number => {
  const td = tender.data;
  const od = offer.data;
  const { discountRate } = tcoVariables;
  let tempServiceCosts = 0;
  if (td.tcoYears) {
    for (let i = 1; i <= td.tcoYears; i += 1) {
      tempServiceCosts
        += (totalYearlyServiceCosts(offer, tender) + (od.greenOwnerTax ?? 0))
        * (1 + discountRate / 100) ** -i;
    }
    tempServiceCosts *= tenderTotalCars(offer, tender);
  }
  return tempServiceCosts;
};

export const takeBackPrice = (offer: Offer5086, tender: Tender5086): number => {
  const td = tender.data;
  const od = offer.data;
  const { discountRate } = tcoVariables;
  return -(od.priceBuyback ?? -0) // The -0 is to remove the sign when there is no buy-back price
    * tenderTotalCars(offer, tender)
    * (1 + discountRate / 100)
    ** -(td.tcoYears ?? 0);
};

export const investmentCosts = (offer: Offer5086, tender: Tender5086): number => {
  const od = offer.data;
  return (priceInclRegistrationPerVehicle(offer)
    + (od.priceServicePeriod ?? 0)) * tenderTotalCars(offer, tender);
};

export const totalSkiShare = (offer: Offer5086, tender: Tender5086): number => {
  const td = tender.data;
  const skiShare = skiSharePerVehicle(offer, tender);
  const initialSkiShare = skiShare * (td.carsBoughtInitially ?? 0);
  const optionalSkiShare = skiShare * (td.optionalCarsAfterAgreementSelected ? (td.optionalCarsAfterAgreement ?? 0) : 0);
  return initialSkiShare + optionalSkiShare;
};

export const calculateTCOPrice = (offer: Offer5086, tender: Tender5086): number => {
  const td = tender.data;
  const od = offer.data;
  const initialAndOptionalCars = tenderTotalCars(offer, tender);
  const modification = td.vehicleModification && td.vehicleModificationPrice === PriceTypesEnum.FIXED_PRICE
    ? od.priceVehicleModificationFixed ?? 0
    : (td.vehicleModificationEstimatedHours ?? 0) * (od.priceVehicleModificationHourlyRate ?? 0);
  const totalDeliveryPrice = priceDeliveryPerVehicle(offer);
  const investmentCostsChargingStations = (od.propellantType === PropellantEnum.ELECTRICITY
    || od.propellantType === PropellantEnum.PLUGIN_HYBRID) && td.tcoYears
    && od.chargingStationPrice
    ? (od.chargingStationPrice * td.tcoYears)
    / tcoVariables.expectedLifetimeForChargingStations : 0;
  // TODO: Mangler Finansieret ved leasing

  return (
    initialAndOptionalCars
    * (modification + totalDeliveryPrice)
    + (investmentCosts(offer, tender) + investmentCostsChargingStations + takeBackPrice(offer, tender)
      + operatingCosts(offer, tender) + serviceCosts(offer, tender))
    + totalSkiShare(offer, tender) - sumOfAllCustomerCars(offer)
  );
};

export const calculateNonTCOPrice = (offer: Offer5086): number => offer.data.priceTotal ?? 0;

export const calculatePrice = (offer: Offer5086, tender: Tender5086): number => {
  if (tender.awardCriteriaType === 'TCO') {
    return calculateTCOPrice(offer, tender);
  }
  return calculateNonTCOPrice(offer);
};

const checkValidScores = (offerEvaluation: OfferEvaluation): boolean => {
  if (offerEvaluation.data) {
    if (offerEvaluation.data.some((data) => data.score)) {
      return true;
    }
  }

  return false;
};

export const getExtendedPriceSpan = (
  prices: number[],
  lowestPrice: number,
  tender: Tender5086,
): number | undefined => {
  const td = tender.data;
  if (!hasValue(td.bpqCostsPercentage)) {
    console.error('Offer precondition failed. Missing bpqCostsPercentage');
    return undefined;
  }
  const costPercentage = td.bpqCostsPercentage / 100;
  if (td.bpqExtendedCostsPercentage) {
    const offersOutsidePriceSpan = prices
      .filter((p) => p > lowestPrice * (1 + costPercentage))
      .length;
    if (offersOutsidePriceSpan >= prices.length / 2) {
      return td.bpqExtendedCostsPercentage / 100;
    }
  }
  return undefined;
};

export const calculatePriceScore = (conditionalOffers: Offer[], offer: Offer5086, tender: Tender5086): number => {
  const td = tender.data;
  if (!td.bpqCostsPercentage) {
    return 0;
  }
  const prices = conditionalOffers.map((o) => calculateTCOPrice(o, tender));
  const lowestPrice = Math.min(...prices);

  let costPercentage = td.bpqCostsPercentage / 100;
  costPercentage = getExtendedPriceSpan(prices, lowestPrice, tender) ?? costPercentage;

  const alpha = (1 - 10)
    / (lowestPrice * (1 + costPercentage) - lowestPrice);
  const beta = 10 - alpha * lowestPrice;
  const score = alpha * calculateTCOPrice(offer, tender) + beta;
  return score > 1 ? score : 1;
};

function getOfferEvaluationElementScore(offerEvaluationData: OfferEvaluationData[], key: BPQCriteriaEnum) {
  const offerEvaluationElement = offerEvaluationData.find((o: OfferEvaluationData) => o.text === key);

  if (offerEvaluationElement) {
    return offerEvaluationElement.score ?? 0;
  }

  return 0;
}

export const calculateBPQRatioScore = (conditionalOffers: Offer5086[], tender: Tender5086, offerEvaluation?: OfferEvaluation): number => {
  const td = tender.data;

  if (td.bpqRatioPercentage
    && conditionalOffers.length > 0
    && td.bpqCostsPercentage && offerEvaluation
  ) {
    const drivingCharacteristicsPercentage = (td.bpqCriteriaDrivingCharacteristics ?? 0) / 100;
    const driversSeatAdjustmentPercentage = (td.bpqCriteriaDriversSeatAdjustment ?? 0) / 100;
    const entryExitConditionsPercentage = (td.bpqCriteriaEntryExitConditions ?? 0) / 100;
    const viewPercentage = (td.bpqCriteriaView ?? 0) / 100;
    const cabinNoisePercentage = (td.bpqCriteriaCabinNoise ?? 0) / 100;
    const instrumentationPercentage = (td.bpqCriteriaInstrumentation ?? 0) / 100;
    const comfortPercentage = (td.bpqCriteriaComfort ?? 0) / 100;

    if (offerEvaluation.data && checkValidScores(offerEvaluation)) {
      const totalScore = getOfferEvaluationElementScore(offerEvaluation.data, 'DrivingCharacteristics') * drivingCharacteristicsPercentage
        + getOfferEvaluationElementScore(offerEvaluation.data, 'DriversSeatAdjustment') * driversSeatAdjustmentPercentage
        + getOfferEvaluationElementScore(offerEvaluation.data, 'EntryExitConditions') * entryExitConditionsPercentage
        + getOfferEvaluationElementScore(offerEvaluation.data, 'View') * viewPercentage
        + getOfferEvaluationElementScore(offerEvaluation.data, 'CabinNoise') * cabinNoisePercentage
        + getOfferEvaluationElementScore(offerEvaluation.data, 'Instrumentation') * instrumentationPercentage
        + getOfferEvaluationElementScore(offerEvaluation.data, 'Comfort') * comfortPercentage;

      return totalScore;
    }
  }

  return 0;
};

export const qualityRatioPercentage = (tender: Tender5086) => {
  const td = tender.data;
  if (td.bpqRatioPercentage === 'Anden procentdel') {
    return td.bpqRatioCustomPercentage ?? 0;
  }
  return parseFloat(td.bpqRatioPercentage ?? '0');
};

export const calculateTotalScore = (conditionalOffers: Offer[], offer: Offer, tender: Tender5086, offerEvaluation?: OfferEvaluation): number => {
  const td = tender.data;
  if (
    td.bpqRatioPercentage
    && conditionalOffers.length > 0
  ) {
    const bpqRatioScore = calculateBPQRatioScore(conditionalOffers, tender, offerEvaluation);
    const priceScore = calculatePriceScore(conditionalOffers, offer, tender);

    const bpqRatioWeight = qualityRatioPercentage(tender) / 100;
    if (bpqRatioWeight === 0) {
      throw new Error('Cannot calculate bpqRatioWeight');
    }

    const priceWeight = 1 - bpqRatioWeight;

    const totalScore = bpqRatioWeight * bpqRatioScore + priceWeight * priceScore;
    return Number((Math.round(totalScore * 100) / 100).toFixed(2));
  }
  return 0;
};

export const tenderStub: DraftTender5086 = {
  data: {
    propellantTypes: [],
    carsBoughtInitially: 1,
    requestForOfferDone: false,
    specificationDone: false,
    deliveryAgreementDone: false,
    greenOwnerTax: 'Nej',
  },
  agreementName: 'dummy',
  state: TenderStateEnum.Prepare,
};

/* Expose the agreement-specific calculations in a generic way to be used by shared components */
export class Calculator5086 implements Calculator {
  sortByCustomRanking(offers: Offer5086[]): Offer[] {
    return offers.sort(
      (a, b) => (a.data.customRanking ?? 0) - (b.data.customRanking ?? 0),
    );
  }

  calculateRelativeScore(offer: Offer, tender: Tender5086, conditionalOffers: Offer[], offerEvaluation?: OfferEvaluation) {
    return calculateTotalScore(conditionalOffers, offer, tender, offerEvaluation);
  }

  calculatePrice(offer: Offer, tender: Tender5086) {
    return calculatePrice(offer as Offer5086, tender);
  }

  sortByPrice(offers: Offer[], tender: Tender5086) {
    return offers.sort(
      // lowest price first, so a - b
      (a, b) => this.calculatePrice(a, tender) - this.calculatePrice(b, tender),
    );
  }

  sortBPQBestFirst(offers: Offer[], tender: Tender5086, offerEvaluations: OfferEvaluation[]): Offer[] {
    const conditionalOffers = offers.filter(
      (o) => o.state !== 'Unconditional'
        && !(o.isUnconditional ?? false)
        && !this.isScoreBelowConditionalThreshold_BackendCalculation(o)
        && !this.isScoreBelowConditionalThreshold_FrontendCalculation(o, tender),
    );
    // Highest total score first, so b - a
    return offers.sort(
      (a, b) => calculateTotalScore(conditionalOffers, b, tender, offerEvaluations.find((oe) => oe.offerId === b.id))
        - calculateTotalScore(conditionalOffers, a, tender, offerEvaluations.find((oe) => oe.offerId === a.id)),
    );
  }

  // Offers with points below a certain threshold are marked as unconditional. Remove later as described in #13384
  isScoreBelowConditionalThreshold_FrontendCalculation(_offer: Offer, _tender: Tender5086) {
    // See #13398 for explanation as to why this is constant false
    return false;
  }

  /// Check if score (or pointscore) is below minimum. Agreement specific rule. Currently not used in 50.86
  isScoreBelowConditionalThreshold_BackendCalculation(_offer: Offer) {
    // See #13398 for explanation as to why this is constant false
    // Modify later as part of #13384 to use a flag in offer.data instead
    return false;
  }

  contractSum(offer: Offer5086, tender:Tender5086) {
    return contractSum(offer, tender);
  }

  /** Shown in 'Samlede omkostninger' column for non-BPQ evaluation  */
  totalCost(offer: Offer5086, tender:Tender5086) {
    return this.calculatePrice(offer, tender);
  }

  /** Shown in 'Samlet score' column for BPQ evaluation
   * should represent the factor used for ordering the offers */
  bpqScoreText(offer: Offer, tender:Tender5086, conditionalOffers: Offer[], offerEvaluation?: OfferEvaluation) {
    const score = this.calculateRelativeScore(
      offer,
      tender,
      conditionalOffers,
      offerEvaluation,
    );
    return `${score} point`;
  }
}

export function getCalculator() {
  return new Calculator5086();
}
