<template>
  <div>
    <v-dialog v-model="model" persistent max-width="90%">
      <div class="bg-canvas nonScrollable">
        <div v-if="!isLoading">
          <v-card class="sticky-banner pa-2 center" rounded="0">
            <div class="d-flex">
              <h1 class="document-drafter-header ml-8 mt-0 py-4">
                Kravspecifikation og Digital Tilbudsliste
                <LastUpdatedBy v-if="questionnaire" :document="questionnaire.questionnaire" />
              </h1>
              <v-spacer />
              <v-card-actions class="mr-8 justify-center">
                <v-progress-circular
                  indeterminate
                  color="accent"
                  v-if="isRefreshing || isLoading"
                  class="mr-3"
                  data-cy-progress
                ></v-progress-circular>
                <v-btn
                  v-if="showDebugButton"
                  class="secondary-button-mini ml-2 mr-2"
                  @click="debug = !debug"
                >
                  DEBUG
                </v-btn>
                <OfferSandbox :tender="tender" v-if="!showIntro" />
                <v-btn
                  class="secondary-button-mini"
                  @click="togglePreview()"
                  v-if="!showIntro"
                >
                  {{ !previewEnabled ? "Preview" : "Luk preview" }}
                  <v-icon class="ml-4" end> mdi-file-outline</v-icon>
                </v-btn>
                <v-btn
                  class="secondary-button-mini mr-2 ml-2"
                  @click="showIntro = true"
                  v-if="!showIntro"
                >
                  Hjælp
                  <v-icon class="ml-4" end>mdi-help-circle-outline</v-icon>
                </v-btn>
                <ShareButton
                  :fab="false"
                  :primary="false"
                  :icon="true"
                  :text="true"
                  :tender="tender"
                  v-if="!showIntro"
                />
              </v-card-actions>
              <v-btn
                class="ma-2 center"
                icon
                :disabled="isRefreshing"
                @click="closeDialog()"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </v-card>
          <div v-if="!showIntro">
            <!-- Vertical sections -->
            <div class="d-flex" v-if="tab === 0">
              <v-card rounded="0" class="scroller ma-2 sticky-steppers" min-width="20vw">
                <v-tabs
                  v-if="!showIntro"
                  v-model="tab"
                  bg-color="transparent"
                  color="primary"
                  :show-arrows="false"
                  height="62"
                  hide-slider
                  grow
                >
                  <v-tab class="bg-accent text-white tabtitle" @click="mandatoryIndex = 1">
                    Faste<br>kravområder
                  </v-tab>
                  <v-tab class="bg-secondary text-white tabtitle">
                    Supplerende<br>kravområder
                  </v-tab>
                </v-tabs>
                <v-window
                  class="tile bg-accent elevation-0 pb-0"
                  non-linear
                  direction="vertical"
                >
                  <v-window-item>
                    <button
                      type="button"
                      v-for="(category, index) of mandatoryCategories
                        ? mandatoryCategories
                        : []"
                      :class="index === mandatoryIndex - 1 ? 'canvas' : 'accent'"
                      editable
                      class="text-left w-100 d-block pl-10 py-3 pr-3"
                      :key="`category-${tab}-${index}`"
                      :step="index + 1"
                      edit-icon="mdi-check"
                      @click="mandatoryIndex = index + 1"
                    >
                      <span
                        :class="
                          index === mandatoryIndex - 1
                            ? 'text-primary'
                            : 'text-white'
                        "
                      >
                        {{ index + 1 }}. {{ category.text }}
                        <v-icon
                          v-if="validateRequiredQuestionsInSection(index)"
                          :class="index === mandatoryIndex - 1
                            ? 'text-primary' : 'text-white'"
                        >mdi-check</v-icon>
                      </span>
                    </button>
                  </v-window-item>
                </v-window>
              </v-card>
              <!-- Content for the selected section -->
              <v-card
                class="scroller my-2 mr-2"
                rounded="0"
                :min-width="previewEnabled || tooltipEnabled ? '29vw' : '58vw'"
                @wheel.passive="onScrollMandatory"
              >
                <v-card-text>
                  <h2>Faste kravområder</h2>
                  <p>
                    Her skal du uddybe dit indkøb<br>
                    &dash; bl.a. med informationer som er nødvendige for at kunne prisevaluere de
                    indkomne tilbud.
                  </p>
                  <div
                    v-for="(question, i) of mandatoryQuestions"
                    :key="question.questionId"
                  >
                    <DDQuestionComponent
                      :class="
                        question.questionType === 'HEADLINE1' && i > 0 ? 'move-down' : ''
                      "
                      :id="
                        question.questionType === 'HEADLINE1'
                          ? question.questionId
                          : ''
                      "
                      :question="question"
                      :prefix="getQuestionNumber(question)"
                      :required="question.required"
                      :readonly="isQuestionReadOnly(question)"
                      :min-value="minValue(question)"
                      :max-value="maxValue(question)"
                      :min-date="minDate(question)"
                      :show-included="false"
                      :disabled="isLoading || isRefreshingForm"
                      :debug="debug"
                      @questionnaireUpdate="updateQuestion"
                      @toggleTooltipView="toggleTooltipView"
                    />
                  </div>
                  <div>
                    <hr>
                    <p>
                      Når du har udfyldt de faste kravområder, er din kravspecifikation som
                      udgangspunkt færdig.
                    </p>
                    <p>
                      Du kan, hvis du har særlige behov, vælge at stille yderligere krav under
                      de supplerende kravområder.
                    </p>
                    <p>
                      Vær som altid opmærksom på, at kravene kan have betydning for prisen.
                    </p>
                    <v-btn
                      @click="closeDialog()"
                      class="mt-3"
                      variant="tonal"
                    >
                      Gem og luk
                    </v-btn>
                    <v-btn
                      @click="tab = 1"
                      class="mt-3 ml-3"
                      variant="tonal"
                    >
                      Se supplerende krav
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
              <v-card
                v-if="previewEnabled"
                class="scroller my-2 mr-2"
                rounded="0"
                min-width="29vw"
                @wheel.passive="onScrollMandatory"
              >
                <v-card-text>
                  <div
                    v-for="question of questionsWithAnswers
                      ? questionsWithAnswers
                      : []"
                    :key="question.questionId"
                  >
                    <DDQuestionComponent
                      :id="
                        question.questionType === 'HEADLINE1'
                          ? question.questionId
                          : ''
                      "
                      :question="question"
                      :prefix="getQuestionNumber(question)"
                      :required="question.required"
                      :readonly="true"
                      :show-included="false"
                      :disabled="isLoading || isRefreshingForm"
                      :debug="debug"
                      @questionnaireUpdate="updateQuestion"
                      @toggleTooltipView="toggleTooltipView"
                    />
                  </div>
                </v-card-text>
              </v-card>
              <v-card
                v-if="tooltipEnabled"
                class="scroller my-2 mr-2"
                rounded="0"
                min-width="29vw"
                @wheel.passive="onScrollOptional"
              >
                <v-btn class="float-right ma-2" icon @click="closeTooltipView()">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-card-text>
                  <div v-html="tooltipText"></div>
                </v-card-text>
              </v-card>
            </div>
            <!-- Vertical sections -->
            <div class="d-flex" v-if="tab === 1">
              <v-card rounded="0" class="scroller ma-2 sticky-steppers" min-width="20vw">
                <v-tabs
                  v-if="!showIntro"
                  v-model="tab"
                  bg-color="transparent"
                  color="primary"
                  :show-arrows="false"
                  height="62"
                  hide-slider
                  grow
                >
                  <v-tab class="bg-accent text-white tabtitle">
                    Faste<br>kravområder
                  </v-tab>
                  <v-tab class="bg-secondary text-white tabtitle">
                    Supplerende<br>kravområder
                  </v-tab>
                </v-tabs>
                <v-window
                  class="tile bg-secondary elevation-0 pb-0"
                  non-linear
                  direction="vertical"
                >
                  <v-window-item>
                    <button
                      v-for="(category, index) of optionalCategories
                        ? optionalCategories
                        : []"
                      :class="
                        index === optionalIndex - 1 ? 'canvas' : 'secondary'
                      "
                      type="button"
                      editable
                      class="text-left w-100 d-block pl-10 py-3 pr-3"
                      :key="`category-${index}`"
                      :step="index + optionalIndexOffset + 1"
                      edit-icon="mdi-check"
                      @click="optionalIndex = index + optionalIndexOffset + 1"
                    >
                      <div
                        :class="
                          index === optionalIndex - 1
                            ? 'text-primary'
                            : 'text-white'
                        "
                      >
                        <span> {{ index + optionalIndexOffset + 1 }}. {{ category.text }}</span>
                        <transition name="slide-fade" mode="out-in">
                          <v-chip
                            v-if="optionalQuestionsAddedInSection(index) > 0"
                            class="ml-1"
                            :key="optionalQuestionsAddedInSection(index)"
                          >
                            {{ optionalQuestionsAddedInSection(index) }} krav
                          </v-chip>
                        </transition>
                      </div>
                    </button>
                  </v-window-item>
                </v-window>
              </v-card>
              <!-- Content for the selected section -->
              <v-card
                class="scroller my-2 mr-2"
                rounded="0"
                :min-width="previewEnabled || tooltipEnabled ? '29vw' : '58vw'"
                @wheel.passive="onScrollOptional"
              >
                <v-card-text>
                  <h2>Supplerende kravområder</h2>
                  <div>
                    <p>
                      Her kan du, hvis du har særlige behov, vælge at stille supplerende krav.
                    </p>
                    <p>
                      Dokumentet er udfyldt med krav, der tidligere har været relevante at bruge.
                      Du kan tilføje helt nye krav under hvert område.
                    </p>
                    <p>
                      <strong>Bemærk</strong>: Du skal kun stille de krav, der er relevante for dig
                      – og du skal ikke stille højere krav, end hvad du har et sagligt behov
                      for at få opfyldt. Vær som altid opmærksom på, at kravene kan have
                      betydning for prisen.
                    </p>
                  </div>
                  <div
                    v-for="(question, i) of optionalQuestions"
                    :key="question.questionId"
                  >
                    <DDQuestionComponent
                      :class="
                        question.questionType === 'HEADLINE1' && i > 0 ? 'move-down' : ''
                      "
                      :id="
                        question.questionType === 'HEADLINE1'
                          ? question.questionId
                          : ''
                      "
                      :question="question"
                      :prefix="getQuestionNumber(question)"
                      :required="question.required"
                      :min-value="minValue(question)"
                      :max-value="maxValue(question)"
                      :min-date="minDate(question)"
                      :show-included="true"
                      :disabled="isLoading || isRefreshingForm"
                      :debug="debug"
                      @questionnaireUpdate="updateQuestion"
                      @toggleTooltipView="toggleTooltipView"
                    />
                  </div>
                </v-card-text>
              </v-card>

              <!-- Preview -->
              <v-card
                v-if="previewEnabled"
                class="scroller my-2 mr-2"
                rounded="0"
                min-width="29vw"
                @wheel.passive="onScrollOptional"
              >
                <v-card-text>
                  <div
                    v-for="question of questionsWithAnswers
                      ? questionsWithAnswers
                      : []"
                    :key="question.questionId"
                  >
                    <DDQuestionComponent
                      :class="
                        question.questionType === 'HEADLINE1' ? 'move-down' : ''
                      "
                      :id="
                        question.questionType === 'HEADLINE1'
                          ? question.questionId
                          : ''
                      "
                      :question="question"
                      :prefix="getQuestionNumber(question)"
                      :required="question.required"
                      :debug="debug"
                      :readonly="true"
                      :show-included="false"
                      :disabled="isLoading || isRefreshingForm"
                      @questionnaireUpdate="updateQuestion"
                      @toggleTooltipView="toggleTooltipView"
                    />
                  </div>
                </v-card-text>
              </v-card>
              <v-card
                v-if="tooltipEnabled"
                class="my-2 mr-2"
                rounded="0"
                min-width="29vw"
                @wheel.passive="onScrollOptional"
              >
                <v-btn class="float-right ma-2" icon @click="closeTooltipView()">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-card-text>
                  <div v-html="tooltipText"></div>
                </v-card-text>
              </v-card>
            </div>
          </div>
          <div v-else>
            <v-card class="scroller ma-2" rounded="0">
              <v-card-text>
                <p>
                  Du skal nu oprette den digitale kravspecifikation med
                  fire <strong>faste kravområder</strong>:
                </p>

                <div class="mt-2">
                  <v-chip class="bg-accent">1</v-chip>
                  <span class="ml-3">Basisinformation</span>
                </div>
                <div class="mt-2">
                  <v-chip class="bg-accent">2</v-chip>
                  <span class="ml-3">Beskrivelse af indkøbet</span>
                </div>
                <div class="mt-2">
                  <v-chip class="bg-accent">3</v-chip>
                  <span class="ml-3">Drivmiddel</span>
                </div>
                <div class="mt-2">
                  <v-chip class="bg-accent">4</v-chip>
                  <span class="ml-3">Grøn ejerafgift</span>
                </div>
                <p class="mt-2">
                  Du kan supplere med yderligere krav, hvis du har særlige krav eller
                  specifikationer til fx opbygning, miljøkrav, service, sikkerhed mv.
                </p>
                <p>
                  Under de <strong>supplerende kravområder</strong> finder du en række
                  eksempler på krav, der kan være relevante at bruge. Du kan også tilføje
                  helt nye krav, som ikke er i dokumentet.
                </p>
                <p>
                  Din digitale kravspecifikation bliver anvendt automatisk i hele indkøbsprocessen,
                  fx til at modtage og evaluere tilbud. Du har mulighed for at dele
                  kravspecifikationen med kolleger, mens du udfylder dokumentet.
                  Du kan også gemme dit udkast for at færdiggøre på et senere tidspunkt.
                </p>
                <p>Din kravspecifikation gemmes automatisk løbende.</p>
              </v-card-text>
              <v-card-actions class="justify-center">
                <ArrowButton
                  @click="showIntro = false"
                >
                  Fortsæt
                </ArrowButton>
              </v-card-actions>
            </v-card>
          </div>
        </div>
      </div>
    </v-dialog>
    <LoadingSpinner
      loadingMessage="Henter kravspecifikation"
      :visible="isLoading"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, watch } from 'vue';
import { DateTime } from 'luxon';
import { LoadingSpinner, LastUpdatedBy, DDQuestionComponent, ShareButton, Question, useConfigStore, QuestionnaireWrapper, useDDQuestionnaire } from '@dims/components';
import { Tender5086 } from '@/models/Tender';
import OfferSandbox from '@/components/Offers/OfferSandbox.vue';

const emit = defineEmits<{ close: [] }>();
const { tender } = defineProps<{ tender: Tender5086 }>();
const model = defineModel<boolean>();

const idDeliveryDate = '8d6273ac-785b-4432-b9c8-ed3a287a39a7';
const idCarsBoughtInitially = 'ff45a99b-c729-44e5-9e19-bf0c33c1be43';
const idOptionalCarsAfterAgreement = 'a80516b2-60fd-40e0-9614-9e7dcc1ce76e';
const idTCOYears = 'a9fc571a-ff5b-44b6-bcdb-8be6edf3a73c';
const idHeadlineBaseInformation = 'b599d31d-900a-4b02-a4a7-31535f8a177b';
const idHeadlineDescriptionOfTender = '4d3266d1-d732-4752-b688-02e0bef6fa64';
const idHeadlinePropellantType = 'eeca3195-7b27-4791-9e85-07aadaf3d9cf';
const idHeadlineGreenTax = '7107d1a3-54c8-47c8-af8f-8bc41cffe998';
const mandatoryCategoryIds = [
  idHeadlineBaseInformation,
  idHeadlineDescriptionOfTender,
  idHeadlinePropellantType,
  idHeadlineGreenTax,
];

const config = useConfigStore();

const tab = ref(0);
const indexMandatoryIndex = ref(1);
const indexOptionalIndex = ref(1);
const optionalIndexOffset = 4;
const showIntro = ref(true);
const isLoading = ref(false);
const previewEnabled = ref(true);
const tooltipEnabled = ref(false);
const tooltipText = ref('');
const debug = ref(false);

const isRefreshingForm = ref(false);
const isRefreshing = ref(false);

const showDebugButton = config.isDevelopment;
const carsBoughtInitiallyLocalCopy = ref< number | undefined>();

/* 50.86 has specification as a document drafter questionnaire */

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const template = tender.agreementConfiguration.questionnaires.specification!;

const {
  questionnaire,
  showMessage,
  updateQuestion,
  commitChanges,
  isQuestionReadOnly,
  reloadQuestionnaire,
} = useDDQuestionnaire({ tenderId: tender.id, questionnaireName: 'specification' }, template, onQuestionnaireUpdated, isRefreshing, isRefreshingForm);

carsBoughtInitiallyLocalCopy.value = tender.data.carsBoughtInitially ?? 1;

onMounted(async () => {
  isLoading.value = true;
  try {
    await reloadQuestionnaire();
  } catch (error) {
    console.error(error);
    showMessage('Kan ikke hente formular');
  }
  isLoading.value = false;
});

const visibleQuestions = computed((): Question[] => questionnaire.value?.visibleQuestions(debug.value) ?? []);

const questionsWithAnswers = computed((): Question[] => {
  if (questionnaire.value) {
    return questionnaire.value.questionsWithAnswers;
  }
  return [];
});

const mandatoryQuestions = computed((): Question[] => {
  const mandatoryPages = mapToMandatoryPages();
  return mandatoryPages;
});

const mandatoryCategories = computed(() => mandatoryQuestions.value.filter(
  (q) => q.questionType === 'HEADLINE1',
));

const mandatoryIndex = computed({
  get(): number {
    return indexMandatoryIndex.value;
  },
  set(value: number) {
    indexMandatoryIndex.value = value;
    const id = mandatoryCategories.value[value - 1]?.questionId;
    if (id) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
  },
});

const optionalQuestions = computed((): Question[] => {
  const optionalPages = mapToOptionalPages();
  return optionalPages;
});

const optionalCategories = computed(() => optionalQuestions.value.filter((q) => q.questionType === 'HEADLINE1'));

const optionalIndex = computed({
  get(): number {
    return indexOptionalIndex.value;
  },

  set(value: number) {
    indexOptionalIndex.value = value - optionalIndexOffset;
    const id = optionalCategories.value[indexOptionalIndex.value - 1]?.questionId;
    if (id) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
  },
});

function getQuestionNumber(question: Question): string {
  const questions = visibleQuestions.value;
  let index = 0;
  let section = 0;
  questions.find((q) => {
    if (q.questionType === 'HEADLINE1') {
      section += 1;
      index = 0;
    } else if (q.questionType === 'REPEAT' || q.questionType === 'REPEATADDREMOVE' || q.questionType === 'REPEATSPIN') {
      // repeater buttons does not count as questions in the numbering
    } else {
      index += 1;
    }
    return q.questionId === question.questionId;
  });
  return `${section}.${index === 0 ? '' : index} `;
}

function onScrollMandatory(_event: Event) {
  const index = mandatoryCategories.value.findIndex((q) => {
    const element = document.getElementById(q.questionId);
    return element && isElementInViewport(element);
  });
  if (index !== -1) {
    indexMandatoryIndex.value = index + 1;
  }
}

function onScrollOptional(_event: Event) {
  const index = optionalCategories.value.findIndex((q) => {
    const element = document.getElementById(q.questionId);
    return element && isElementInViewport(element);
  });
  if (index !== -1) {
    indexOptionalIndex.value = index + 1;
  }
}

function isElementInViewport(el: Element): boolean {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0
      && rect.left >= 0
      && rect.bottom
      <= (window.innerHeight || document.documentElement.clientHeight)
      && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

function mapToMandatoryPages(): Question[] {
  const result: Question[] = [];
  let section: Question[] = [];
  visibleQuestions.value.forEach((question: Question, index: number) => {
    if (question.questionType === 'HEADLINE1' && section.length > 0) {
      if (
        section[0] && mandatoryCategoryIds.includes(section[0].questionId)
      ) {
        result.push(...section);
      }
      section = [];
    }
    section.push(question);
    if (
      index === visibleQuestions.value.length - 1
        && section[0] && mandatoryCategoryIds.includes(section[0].questionId)
    ) {
      result.push(...section);
    }
  });
  return result;
}

function mapToOptionalPages(): Question[] {
  const result: Question[] = [];
  let section: Question[] = [];
  visibleQuestions.value.forEach((question: Question, index: number) => {
    if (question.questionType === 'HEADLINE1' && section.length > 0) {
      if (
        section[0] && !mandatoryCategoryIds.includes(section[0].questionId)
      ) {
        result.push(...section);
      }
      section = [];
    }
    section.push(question);
    if (
      index === visibleQuestions.value.length - 1
        && section[0] && !mandatoryCategoryIds.includes(section[0].questionId)
    ) {
      result.push(...section);
    }
  });
  return result;
}

function minValue(question: Question): number | undefined {
  if (question.questionId === idCarsBoughtInitially) {
    return 1;
  }
  if (question.questionId === idOptionalCarsAfterAgreement) {
    return 1;
  }
  if (question.questionId === idTCOYears) {
    return 1;
  }
  if (question.dataFieldType === 'Number') {
    return 0;
  }
  return undefined;
}

function maxValue(question: Question): number | undefined {
  // Validation for specification can be added here
  if (question.questionId === idOptionalCarsAfterAgreement) {
    return carsBoughtInitiallyLocalCopy.value ?? 1;
  }
  if (question.dataFieldType === 'Number') {
    return undefined;
  }
  return undefined;
}

function minDate(question: Question): string | undefined {
  if (question.questionId === idDeliveryDate) {
    return tender.deadlineForTender ?? undefined;
  }
  if (question.dataFieldType === 'Date') {
    return DateTime.local().toISODate();
  }
  return undefined;
}

function onQuestionnaireUpdated(questionnaire1: QuestionnaireWrapper) {
  // As maxValue validator is working across fields we need to save a local copy of cars bought initially
  const field = questionnaire1.content.questions.filter((q) => q.questionId === idCarsBoughtInitially)[0];
  if (field) {
    const initialCars = +(field.answers?.first().value ?? 1);
    carsBoughtInitiallyLocalCopy.value = initialCars;
  }
}

function optionalQuestionsAddedInSection(index: number): number {
  // Returns the amount of optional answers entered - displayed in v-chip in v-stepper section
  const headlines = optionalQuestions.value.filter((q) => q.questionType === 'HEADLINE1');
  const startHeadline = headlines[index];
  if (!startHeadline) {
    throw new Error(`Cannot count optional answers in section ${index}`);
  }
  const endHeadline = headlines[index + 1];

  const startIndex = optionalQuestions.value.findIndex(
    (q) => q.questionId === startHeadline.questionId,
  ) + 1;

  const endIndex = endHeadline
    ? optionalQuestions.value.findIndex(
      (q) => q.questionId === endHeadline.questionId,
    )
    : optionalQuestions.value.length;

  const optionalRequirements = optionalQuestions.value
    .slice(startIndex, endIndex)
    .flatMap((r) => r.answers ?? [])
    .filter((a) => !!a.value || a.selected).length;

  return optionalRequirements;
}

function validateRequiredQuestionsInSection(index: number) {
  let counter = -1;
  for (const q of mandatoryQuestions.value) {
    if (q.questionType === 'HEADLINE1') {
      counter += 1;
    } else if (index === counter
        && q.required
        && !(q.answers ?? []).some((a) => a.value || a.selected)) {
      return false;
    }
  }
  return true;
}

function closeDialog() {
  model.value = false;
}

watch(
  model,
  async () => {
    if (model.value) {
      // on dialog open
      // reload questionnaire since it may have changed
      await reloadQuestionnaire();
    } else {
      // on dialog close
      await commitChanges();
      mandatoryIndex.value = 1;
      optionalIndex.value = 1;
      emit('close');
    }
  },
);

function toggleTooltipView(text?: string) {
  if (text && tooltipText.value === text) {
    tooltipEnabled.value = false;
    tooltipText.value = '';
  } else {
    tooltipEnabled.value = true;
    previewEnabled.value = false;
  }
  if (tooltipEnabled.value && text) {
    tooltipText.value = text;
  }
}

function closeTooltipView() {
  tooltipEnabled.value = false;
  tooltipText.value = '';
}

function togglePreview() {
  previewEnabled.value = !previewEnabled.value;
  if (previewEnabled.value && tooltipEnabled.value) {
    tooltipEnabled.value = false;
  }
}

</script>

<style scoped>
body {
  overflow: hidden;
}

.sticky-banner {
  position: -webkit-sticky;
  position: sticky;
  align-self: flex-start;
  top: 0;
  z-index: 1;
}

.v-divider {
  border-style: dashed !important;
}

.nonScrollable {
  overflow: hidden;
}

.scroller {
  overflow-y: auto;
  height: 70vh;
}

.move-down {
  padding-top: 30px;
}

.tile {
  border-radius: 0 !important;
}

.sticky-steppers {
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  align-self: flex-start;
  z-index: 1;
}

.document-drafter-header {
  font-family: "Segoe UI", "Segoe UI Web (West European)",
  -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}

.slide-fade-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s ease-in;
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active for <2.1.8 */
{
  transform: translateX(10px) scale(2);
  opacity: 0;
}

.right {
  float: right;
}

.v-btn span {
  text-align: left;
  width: 190px;
}

.v-btn.text-white {
  color: white !important;
}

.tabtitle {
  font-size: 18px;
  line-height: 18px;
}
</style>
