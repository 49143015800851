<template>
  <button
    type="button"
    @click="invoiceCollapsed = !invoiceCollapsed"
    class="collapsable w-100 text-left pa-1"
    :class="invoiceCollapsed ? 'collapsed' : ''"
  >
    <h2>Eksempel på faktura</h2>
    <p>{{ description }}</p>
    <p>Alle priser opgives ekskl. moms.</p>
    <div class="invoice">
      <h1>Faktura</h1>
      <transition name="slide-fade" mode="out-in">
        <div v-if="!invoiceCollapsed">
          <v-table dense>
            <thead>
              <tr class="font-weight-bold">
                <td>Virksomhed</td>
                <td>Kunde</td>
                <td>SKI-ID</td>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>{{ offer.sender.companyName }}</td>
                <td>{{ tender.user.companyName }}</td>
                <td>{{ tender.friendlyId }}</td>
              </tr>
            </tbody>
          </v-table>

          <v-table dense class="mt-2">
            <thead>
              <tr class="font-weight-bold">
                <td>Beskrivelse</td>
                <td>Antal</td>
                <td>Pris pr. stk.</td>
                <td>Beløb</td>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td class="font-weight-bold">Køretøjets pris inkl. udstyr</td>
                <td>{{ tender.data.carsBoughtInitially }}</td>
                <td class="text-right">
                  {{ toCurrencyFormat(offer.data.priceTotal) }}
                </td>
                <td class="text-right">
                  {{
                    toCurrencyFormat(
                      (tender.data.carsBoughtInitially ?? 0)
                        * (offer.data.priceTotal ?? 0),
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">Opbygning</td>
                <!-- Antal -->
                <td>
                  {{
                    tender.data.vehicleModification
                      ? tender.data.carsBoughtInitially
                      : "-"
                  }}
                </td>
                <!-- Pris -->
                <td class="text-right">
                  {{
                    tender.data.vehicleModification
                      ? toCurrencyFormat(priceModificationPerVehicle(offer, tender))
                      : "---"
                  }}
                </td>
                <!-- Beløb -->
                <td class="text-right">
                  {{
                    tender.data.vehicleModification
                      ? toCurrencyFormat(
                        (tender.data.carsBoughtInitially ?? 0)
                          * priceModificationPerVehicle(offer, tender),
                      )
                      : "---"
                  }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Serviceydelser mv.,</strong> total for hele TCO-perioden (NB.
                  Faktureres årligt til kunden)
                </td>
                <td>
                  {{
                    totalServiceCosts(offer)
                      ? tender.data.carsBoughtInitially
                      : "-"
                  }}
                </td>
                <td class="text-right">
                  {{
                    totalServiceCosts(offer)
                      ? toCurrencyFormat(totalServiceCosts(offer))
                      : "---"
                  }}
                </td>
                <td class="text-right">
                  {{
                    totalServiceCosts(offer)
                      ? toCurrencyFormat(
                        (tender.data.carsBoughtInitially ?? 0)
                          * totalServiceCosts(offer),
                      )
                      : "---"
                  }}
                </td>
              </tr>
              <tr>
                <td><strong>Udvidet garantiperiode</strong> (dvs. udover år 1 og 2)</td>
                <!-- Antal -->
                <td>
                  {{
                    tender.data.extendedWarrantyPeriod
                      ? tender.data.carsBoughtInitially
                      : "-"
                  }}
                </td>
                <!-- Pris -->
                <td class="text-right">
                  {{ toCurrencyFormat(offer.data.priceServicePeriod) }}
                </td>
                <!-- Beløb -->
                <td class="text-right">
                  {{
                    toCurrencyFormat(
                      (tender.data.carsBoughtInitially ?? 0)
                        * (offer.data.priceServicePeriod ?? 0),
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">Levering hos kunde</td>
                <td>
                  {{
                    tender.data.deliveryCondition
                      === DeliveryConditionTypesEnum.DELIVERY_AT_CUSTOMER
                      ? tender.data.carsBoughtInitially
                      : "-"
                  }}
                </td>
                <td class="text-right">
                  {{ toCurrencyFormat(offer.data.priceDeliveryAtCustomer) }}
                </td>
                <td class="text-right">
                  {{
                    toCurrencyFormat(
                      (tender.data.carsBoughtInitially ?? 0)
                        * (offer.data.priceDeliveryAtCustomer ?? 0),
                    )
                  }}
                </td>
              </tr>
              <tr style="background-color: lightgrey">
                <td>
                  <em><strong>Subtotal</strong> hvoraf SKI rabatandel beregnes</em>
                </td>
                <td>{{ tender.data.carsBoughtInitially }}</td>
                <td class="text-right">
                  {{ toCurrencyFormat(subTotal(offer, tender)) }}
                </td>
                <td class="text-right">
                  {{ toCurrencyFormat((tender.data.carsBoughtInitially ?? 0) * subTotal(offer, tender)) }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>SKI rabatandel 1%</strong><br />
                  (Leverandøren skal, på vegne af kunden, indrapportere og
                  betale beløbet til SKI efter fakturering og levering)
                </td>
                <td>{{ tender.data.carsBoughtInitially }}</td>
                <td class="text-right">
                  {{ toCurrencyFormat(skiSharePerVehicle(offer, tender)) }}
                </td>
                <td class="text-right">
                  {{
                    toCurrencyFormat(
                      (tender.data.carsBoughtInitially ?? 0) * skiSharePerVehicle(offer, tender),
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">Registreringsafgift</td>
                <td>{{ tender.data.carsBoughtInitially }}</td>
                <td class="text-right">
                  {{ toCurrencyFormat(offer.data.registrationTax) }}
                </td>
                <td class="text-right">
                  {{
                    toCurrencyFormat(
                      (tender.data.carsBoughtInitially ?? 0)
                        * (offer.data.registrationTax ?? 0),
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Leveringsomkostninger</strong> inkl. indregistrering og
                  nummerplade
                </td>
                <td>{{ tender.data.carsBoughtInitially }}</td>
                <td class="text-right">
                  {{ toCurrencyFormat(offer.data.priceDelivery) }}
                </td>
                <td class="text-right">
                  {{
                    toCurrencyFormat(
                      (tender.data.carsBoughtInitially ?? 0)
                        * (offer.data.priceDelivery ?? 0),
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">Total</td>
                <td>{{ tender.data.carsBoughtInitially }}</td>
                <td class="text-right">
                  {{ toCurrencyFormat(totalCosts(offer, tender)) }}
                </td>
                <td class="text-right">
                  {{
                    toCurrencyFormat(
                      (tender.data.carsBoughtInitially ?? 0) * totalCosts(offer, tender),
                    )
                  }}
                </td>
              </tr>
              <tr v-if="customerCarsCount > 0">
                <td class="font-weight-bold">Bil i bytte</td>
                <td>{{ customerCarsCount }}</td>
                <td class="text-right">
                  ---
                </td>
                <td class="text-right">
                  {{ toCurrencyFormat(-sumOfAllCustomerCars) }}
                </td>
              </tr>
              <!-- PRICES TOTAL -->
              <tr style="background-color: lightgrey">
                <td colspan="3" class="text-right">
                  <strong>Pris i alt</strong> initialt køb
                </td>
                <td class="text-right">
                  <strong>{{ toCurrencyFormat(contractSum(offer, tender)) }}</strong>
                </td>
              </tr>
              <tr style="background-color: lightgrey">
                <td colspan="3" class="text-right">
                  <span><strong>Pris pr. efterfølgende option</strong></span>
                  <br />
                  <span>(1 option = 1 køretøj)</span>
                </td>
                <td class="text-right">
                  <strong>{{ toCurrencyFormat(totalCosts(offer, tender)) }}</strong>
                </td>
              </tr>
              <tr style="background-color: lightgrey">
                <td colspan="3" class="text-right">
                  <span>
                    <strong>SKI rabatandel 1% pr. efterfølgende option/køretøj</strong>
                  </span>
                  <br />
                  <span>(Indrapporteres og betales, på vegne af kunden, til SKI
                    efter fakturering og levering)</span>
                </td>
                <td class="text-right">
                  <strong>{{ toCurrencyFormat(subTotal(offer, tender) * 0.01) }}</strong>
                </td>
              </tr>
            </tbody>
          </v-table>
        </div>
      </transition>
    </div>
  </button>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { toCurrencyFormat } from '@dims/components';
import {
  contractSum,
  priceModificationPerVehicle,
  skiSharePerVehicle,
  subTotal,
  totalCosts,
  totalServiceCosts,
} from '@/services/utils5086';
import DeliveryConditionTypesEnum from '@/models/DeliveryConditionTypes';

import { Offer5086 } from '@/models/Offer';
import { Tender5086 } from '@/models/Tender';

const { tender, offer } = defineProps<{
  tender: Tender5086,
  offer: Offer5086,
  description: string }>();

const invoiceCollapsed = ref(false);

const sumOfAllCustomerCars = computed(() => {
  const od = offer.data;
  return (
    (od.priceBuyCustomersCar1 ?? 0)
      + (od.priceBuyCustomersCar2 ?? 0)
      + (od.priceBuyCustomersCar3 ?? 0)
      + (od.priceBuyCustomersCar4 ?? 0)
      + (od.priceBuyCustomersCar5 ?? 0)
  );
});

const customerCarsCount = computed(() => {
  let count = 0;
  const td = tender.data;
  if (td.descriptionCustomerCar1) { count += 1; }
  if (td.descriptionCustomerCar2) { count += 1; }
  if (td.descriptionCustomerCar3) { count += 1; }
  if (td.descriptionCustomerCar4) { count += 1; }
  if (td.descriptionCustomerCar5) { count += 1; }
  return count;
});

</script>

<style scoped>

.invoice {
  background: white;
  border: #63756b solid 1px;
  padding: 20px;
  box-shadow: 3px 3px 3px 0 rgb(var(--v-theme-primary));
  font-family: "Courier New", sans-serif;
  /* for PDF generation */
  page-break-inside: avoid;
}

.invoice-sum {
  background: lightgrey;
  font-weight: bolder;
}

.collapsable {
  cursor: pointer;
}

.collapsed {
  overflow: hidden;
  position: relative;
  max-height: 200px;
}

.collapsed:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(transparent 20px, white);
}
</style>
