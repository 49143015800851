import { AwardCriteriaType, labelForRegion, Offer, Region, Tender } from '@dims/components';
import VehicleTypesEnum, { vehicleTypeLabels } from '@/models/VehicleTypes';
import { Tender5086, TenderData5086 } from '../models/Tender';
import { Offer5086 } from '../models/Offer';
import { awardCriteriaType } from '@/services';

function regionTypeLabel(type?: Region | null) {
  return type ? labelForRegion(type) : '';
}
function vehicleTypeLabel(type?: VehicleTypesEnum | null) {
  return type ? vehicleTypeLabels(type) : '';
}
function awardTypeLabel(type?: AwardCriteriaType | null): string {
  return type ? awardCriteriaType.getLabel(type) : '';
}

export default {
  offerLabels(offer: Offer5086) {
    const { tenderData } = offer;
    return [
      vehicleTypeLabel(tenderData.vehicleType),
      regionTypeLabel(tenderData.region),
      awardTypeLabel(tenderData.awardType),
    ];
  },
  tenderValues(tender: Tender5086): { label: string, name: string, value: string }[] {
    const tenderData = tender.data;
    return [
      { label: 'Biltype', name: 'vehicleType', value: vehicleTypeLabel(tenderData.vehicleType) },
      { label: 'Region', name: 'region', value: regionTypeLabel(tenderData.region) },
      { label: 'Tildelingskriterie', name: 'awardType', value: awardTypeLabel(tender.awardCriteriaType) },
    ];
  },
  offerValues(offer: Offer5086): { label: string, name: string, value: string }[] {
    const { tenderData } = offer;
    return [
      { label: 'Biltype', name: 'vehicleType', value: vehicleTypeLabel(tenderData.vehicleType) },
      { label: 'Region', name: 'region', value: regionTypeLabel(tenderData.region) },
      { label: 'Tildelingskriterie', name: 'awardType', value: awardTypeLabel(tenderData.awardType) },
    ];
  },
  offerDetails(offer: Offer) {
    const { brand, model } = (offer as Offer5086).data;
    if (brand ?? model) {
      return `${brand ?? ''}${(brand && model) ? ',' : ''} ${
        model ?? ''
      }`;
    }
    return '';
  },
  offerLongDescription(offer: Offer5086) {
    const { tenderData } = offer;
    const label = vehicleTypeLabel(tenderData.vehicleType);
    return `${tenderData.description ?? ''}, ${label}`;
  },
  tenderLongDescription(tender: Tender) {
    const tenderData = tender.data as TenderData5086;
    const label = vehicleTypeLabel(tenderData.vehicleType);
    return `${tender.description}, ${label}`;
  },
  getColumnValues(tender: Tender5086) {
    return [
      vehicleTypeLabel(tender.data.vehicleType),
      regionTypeLabel(tender.data.region),
      awardTypeLabel(tender.awardCriteriaType),
    ];
  },
  getTableHeaders() {
    return [{
      title: 'Biltype',
      value: 'data.vehicleType',
      sort: (a: string, b: string) => vehicleTypeLabels(a as VehicleTypesEnum)
        .localeCompare(vehicleTypeLabels(b as VehicleTypesEnum)),
      sortable: true,
    },
    {
      title: 'Region',
      align: 'start' as const,
      value: 'data.region',
      sort: (a: string, b: string) => labelForRegion(a as Region)
        .localeCompare(labelForRegion(b as Region)),
      sortable: true,
    },
    {
      title: 'Tildelingskriterie',
      align: 'start' as const,
      value: 'awardType',
      sort: (a: string, b: string) => awardCriteriaType.getLabel(a as AwardCriteriaType)
        .localeCompare(awardCriteriaType.getLabel(b as AwardCriteriaType)),
      sortable: true,
    }];
  },
  searchOffersText(search: string, offers: Offer5086[]): Offer[] {
    const searchString = search.toLowerCase();
    return offers.filter(
      (o) => o.sender.companyName
        .concat(o.data.brand ?? '', o.tenderData.description ?? '') // TODO: Concatenate all text fields?
        .toLowerCase()
        .includes(searchString),
    );
  },
};
