<template>
  <v-container fluid>
    <TenderMaterialIntroText v-if="!readonly" :tender="tender">
      <template #info_start>
        <p>Når du bruger de digitale dokumenter, genbruges informationen på tværs af materialet. Dokumenterne er indbyrdes forbundne, så fx informationer fra kravspecifikationen overføres til leveringskontrakten og opfordring til tilbud.</p>
      </template>
      <template #info_steps>
        <p class="mb-1">Sådan gør du:</p>
        <ol>
          <li>Angiv dine krav i <strong>kravspecifikationen</strong></li>
          <li>Færdiggør <strong>leveringskontrakt</strong> og <strong>opfordring til tilbud</strong> i én arbejdsgang</li>
          <li>Tilføj evt. egne dokumenter og bilag under <strong>Tilføj egne +</strong></li>
          <li>Marker dokumenternes status som <strong>Færdig</strong> for at aktivere knappen <strong>Godkend og offentliggør materiale</strong></li>
          <li>Kontroller og offentliggør dit materiale ved at klikke på <strong>Godkend og offentliggør materiale</strong></li>
        </ol>
      </template>
      <template #info_end>
        <p>
          <b>Bemærk:</b> Når du vælger <b>’Godkend og offentliggør materiale’</b>, offentliggør du <b>ikke</b> for leverandørerne, før du i det efterfølgende trin har kontrolleret dit materiale og er klar. Det er kun de leverandører, der svarer til dit valg af tildelingskriterie, region og type af køretøj, der kan afgive tilbud.
        </p>
        <p>
          Du kan også bruge egne dokumenter. Vær dog opmærksom på, at de ikke kan indgå i den automatiserede evaluering. Du skal derfor selv forestå hele evalueringen.
        </p>
        <p>
          Brug de tre prikker til højre for <b>’Angiv status’</b> herunder, for at se og downloade dokumenterne.
        </p>
        <p>
          Afhold evt. <b>markedsdialog</b>, inden du offentliggør dit indkøb: Markedsdialog kan give dig en bedre forståelse af markedet. Dialogen med leverandørerne kan hjælpe dig med at lave mere realistiske udbudskriterier og krav, som passer til det, markedet faktisk kan tilbyde. Du afholder markedsdialog under fanen ‘Kommunikation’.
        </p>
        <p>
          Har du spørgsmål eller brug for rådgivning, er du altid velkommen til at kontakte os.
        </p>
      </template>
      <p>
        Det er kun de leverandører, der svarer til dit valg af tildelingskriterie, region og type af køretøj, der kan afgive tilbud.
      </p>
    </TenderMaterialIntroText>
    <TenderMaterialTableV2
      :documentList="documentList"
      :uploadModel="uploadModel"
      :tender="tender"
      @setDocumentDoneStatus="setDocumentDoneStatus"
      @removeFile="removeFile"
      @edit="onEdit($event)"
      @preview="onPreview($event)"
      :showFileInputMenu="true"
    >
      <template #editRequestForOffer="{ activatorType }">
        <RequestForOfferDialog
          :activatorType="activatorType"
          :tender="tender"
        />
      </template>
      <template #specificationInfo>
        <SimpleInfoDialog title="Kravspecifikation">
          <p>
            Udfyld den digitale kravspecifikation, hvor du skal tage stilling til de mest almindelige krav på markedet.
            Du kan også supplere med egne krav.
          </p>
          <p>
            Den udfyldte kravspecifikation bliver efter godkendelse automatisk til leverandørens tilbudsliste
            og brugt i den automatiske evaluering.
          </p>
          <p>
            Hvis du uploader din egen kravspecifikation, bliver den ikke automatisk til leverandørens
            tilbudsliste eller indgår i den automatiske evaluering. Du skal derfor selv forestå hele evalueringen.
          </p>
        </SimpleInfoDialog>
      </template>
      <template #requestForOfferInfo>
        <SimpleInfoDialog title="Leveringskontrakt og opfordring til tilbud">
          <p>
            I det digitale flow udfylder du leveringskontrakten og opfordring til tilbud i én arbejdsgang.
            Når du har afgivet alle nødvendige informationer, bliver dokumenterne genereret automatisk. Informationerne
            – fx dit valg af tildelingskriterie – bliver brugt i den automatisk understøttede evaluering.
          </p>
          <p>
            Du kan løbende se og skifte mellem leveringskontrakt og opfordring i preview-funktionen.
          </p>
          <p>
            Hvis du bruger egne dokumenter, kan de ikke indgå i den automatiske evaluering,
            og du skal derfor selv forestå hele evalueringen.
          </p>
        </SimpleInfoDialog>
      </template>
    </TenderMaterialTableV2>
    <v-row v-if="!readonly" no-gutters>
      <v-col style="max-width: 350px !important" class="text-center">
        <img
          alt="Pil ned"
          class="ml-1"
          height="40"
          src="@/assets/arrow_green_down.svg"
        />
        <div class="ml-4">
          <PublishTenderDialog
            :tender="tender"
            :disabled="!mixin.publishTenderEnabled()"
            @updateTab="updateTab($event)"
          />
          <TenderMaterialAfterText :publishTenderEnabled="mixin.publishTenderEnabled()" :isTemplate="isTemplate" />
        </div>
      </v-col>
    </v-row>
    <SpecificationEditDialog
      v-model="showSpecificationEditor"
      :tender="tender"
    />
    <SpecificationPreviewDialogWithoutActivator
      v-model="showSpecificationViewer"
      :tender="tender"
    />
    <LoadingSpinner loadingMessage="Vent venligst" :visible="isLoading" />
  </v-container>
</template>

<script setup lang="ts">

import { ref, onMounted, watch, computed } from 'vue';
import {
  LoadingSpinner,
  MaterialDocument,
  MaterialFileInfo,
  useStore,
  TenderMaterialTableV2,
  SimpleInfoDialog,
  PublishTenderDialog,
  RequestForOfferDialog,
  SpecificationPreviewDialogWithoutActivator,
  TenderMaterialIntroText,
  TenderMaterialAfterText,
  TenderStateEnum,
  TenderMaterialMixin,
} from '@dims/components';
import { Tender5086 } from '@/models/Tender';
import SpecificationEditDialog from '@/components/Tender/Specification/SpecificationEditDialog.vue';

/** Presentations of documents when tender is in draft state.
 * Each of the standard files can be replaced by a custom uploaded file
 * Additional custom files can be uploaded
 * For the standard documents, link to questionnaire dialog
 * For custom documents, download.
 */
const emit = defineEmits<{ isLoaded: [true], updateTab: [string] }>();
const { readonly = false, tender } = defineProps<{ readonly?: boolean, tender: Tender5086 }>();
const store = useStore();
const showSpecificationEditor = ref(false);
const showSpecificationViewer = ref(false);
const isLoading = ref(false);

class TenderMaterialMixin5086 extends TenderMaterialMixin {
  /** 50860017 uses document drafter for specification */
  async isSpecificationComplete() {
    const tender5086 = this.tender as Tender5086;
    const optionsOk = (tender5086.data.carsBoughtInitially ?? 1) >= (tender5086.data.optionalCarsAfterAgreement ?? 0);
    const specComplete = await this.isDocumentDrafterQuestionnaireCompleted('specification');
    return optionsOk && specComplete;
  }
}
const mixin = ref<TenderMaterialMixin>(new TenderMaterialMixin5086(tender, false, true, false, false));

onMounted(() => {
  emit('isLoaded', true);
});

watch(
  () => tender,
  (newTender: Tender5086) => {
    mixin.value.tender = newTender;
  },
);

const documentList = computed(() => mixin.value.getDocumentLines());

async function removeFile(file: MaterialFileInfo) {
  isLoading.value = true;
  try {
    await mixin.value.removeFile(file);
  } catch (e) {
    console.error(e);
    store.setSnackbarText('Kunne ikke slette filen.');
  } finally {
    isLoading.value = false;
  }
}

function onEdit(doc: MaterialDocument) {
  if (doc.kind === 'specification') {
    showSpecificationEditor.value = true;
  }
}

function onPreview(doc: MaterialDocument) {
  if (doc.kind === 'specification') {
    showSpecificationViewer.value = true;
  }
}

function updateTab(tabName: string) {
  emit('updateTab', tabName);
}

async function setDocumentDoneStatus(doc: MaterialDocument, completed: boolean) {
  isLoading.value = true;
  try {
    await mixin.value.setDocumentDoneStatus(doc, completed);
  } catch (e) {
    console.error('Could not update tender', e);
    store.setSnackbarText('Kan ikke markere dokument som færdigt.');
  } finally {
    isLoading.value = false;
  }
}

const uploadModel = computed(() => mixin.value.getUploadModel());

const isTemplate = computed(() => tender.state === TenderStateEnum.Template);

</script>
